import { useState } from 'react';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase/firebaseConfig';

export const useAddData = (token, makeRequest) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const addData = async (personData, url) => {
        setLoading(true);

        const { name, email, phoneNumber, password, verifyPassword } = personData; // Assuming personData contains email and password

        const storedToken = token || localStorage.getItem('token');
        if (!storedToken) {
            console.log("Token is null:", token);
            setError("Token is missing");
            setLoading(false);
            return;
        }

        try {
            // Step 1: Create agent in Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('User created in Firebase Auth:', user);

            // Step 2: Add the user’s UID to agent data and send it to backend
            const response = await makeRequest(
                `http://localhost:5000/api/${url}`,
                'POST',
                {name, email, phoneNumber, password, verifyPassword, uid: user.uid }, // Include UID for reference in database
                storedToken
            );
            
            return response;
        } catch (error) {
            setError(error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return { addData, loading, error };
};
