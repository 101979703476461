import { useState, useEffect } from 'react';

export const useViewData = (token, makeRequest, url, refreshKey) => {
    const [persons, setPersons] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        setPersons(null);
        setLoading(true);

        const fetchData = async () => {
            const storedToken = token || localStorage.getItem('token');
            if (!storedToken) {
                console.log("Token is null:", token);
                setError("Token is missing.");
                setLoading(false);
                return;
            }
            try {
                const response = await makeRequest(
                    `http://localhost:5000/api/${url}`,
                    'GET',
                    null,
                    storedToken
                );
                setPersons(response);
            } catch (error) {
                console.error('Error making authorized request:', error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [token, makeRequest, url, refreshKey]);

    return { persons, loading, error };
};
