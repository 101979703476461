import React, { useState, useEffect } from "react";
import "../../styles/pages/admin.css";
import AgentView from "../AgentView";
import useAuth from "../../hook/useAuth";
import useAuthorizedRequest from "../../hook/useAuthorizedRequest";
import UserView from "../UserView";
import Dashboard from "../Dashborad";
import TourView from "../TourView";

function AdminPage() {
    const [activeButton, setActiveButton] = useState('Dashboard');
    const [data, setData] = useState(null);
    const { token } = useAuth();
    const { makeRequest, error } = useAuthorizedRequest();

    useEffect(() => {
        const fetchData = async () => {
            const storedToken = token || localStorage.getItem('token');
            if (!storedToken) {
                console.log("Token is null:", token);
                return;
            }
            try {
                const response = await makeRequest(
                    'http://localhost:5000/api/get-agents',
                    'GET',
                    null,
                    storedToken
                );
                setData(response);
            } catch (error) {
                console.error('Error making authorized request:', error);
            }
        };

        fetchData();
    }, [token, makeRequest]);

    // if (error) return <p>Error: {error}</p>;

    return (
        <div className="admin-page">
            <Slidebar activeButton={activeButton} onButtonClick={setActiveButton} />
            <BodyContent activeButton={activeButton} data={data} error={error} />
        </div>
    );
}

export default AdminPage;

export function Slidebar({ activeButton, onButtonClick }) {

    return (
        <nav className="sidebar">
            <ul className="silder-list">
                {["Dashboard", "Agents", "Users" , "Tour", "Group Tours", "Slider"].map((button) => (
                    <li
                        key={button}
                        onClick={() => onButtonClick(button)}
                        className={`slider-li ${activeButton === button ? 'active' : ''}`}
                    >
                        <span>{button}</span>
                    </li>
                ))}
                <li
                    onClick={() => onButtonClick('Logout')}
                    className={`slider-li ${activeButton === 'Logout' ? 'active' : ''}`}
                >
                    <span>Logout</span>
                </li>
            </ul>
        </nav>
    )
}

export function BodyContent({ activeButton, data, error }) {
    return (
        <div className="dashboard-page">
            <h1>{activeButton}</h1>
            {activeButton === "Dashboard" && <Dashboard/>}
            {activeButton === "Agents" && <AgentView />}
            {activeButton === "Users" && <UserView />}
            {activeButton === "Tour" && <TourView />}
            {activeButton === "Group Tours" && <p>Group tours management.</p>}
            {activeButton === "Slider" && <p>Edit your sliders here.</p>}
            {activeButton === "Logout" && ""}
        </div>
    )
}