import { useState, useContext, useCallback } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

function useAuth() {
    const [token, setToken] = useState(localStorage.getItem('token'));;
    const [error, setError] = useState("");
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const login = useCallback(async (email, password) => {
        setError("");
        if (!email || !password) {
            setError("Please fill in all fields.");
            return;
        }

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            const fetchedToken = await user.getIdToken();
            console.log(fetchedToken);
            setToken(fetchedToken);
            localStorage.setItem('token', fetchedToken);
            dispatch({ type: "LOGIN", payload: user });
            navigate("/");

        } catch (err) {
            setError("Error during sign-in: " + err.message);
            console.error("Error during sign-in:", err);
        }
    }, [dispatch, navigate]);

    return {
        token,
        error,
        login,
    };
};

export default useAuth;
