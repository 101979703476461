import { useState } from "react";
import CreateTour from "../components/CreateTour";
import Modal from "../components/Modal";

function TourView() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenEditModal = (uid) => {
        // const userToEdit = persons[uid];
        // setEditingUser(userToEdit);
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        // setEditingUser(null);
    };

    return (
        <>
            <button onClick={handleOpenModal}>Create Tour</button>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <CreateTour />
            </Modal>
        </>
    )
}

export default TourView;