import React, { useContext } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import AdminPage from "./pages/admin/AdminPage";
import AdminLoginPage from "./auth/AdminLogin";
import { AuthContext } from "./context/AuthContext";

const RequireAuth = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    console.log(currentUser);
    return currentUser ? children : <Navigate to="/login" />;
}

const router = createBrowserRouter([
    {
        path: '/login',
        element: <AdminLoginPage />,
    },
    {
        path: '/',
        element: (
            <RequireAuth>
                <AdminPage />
            </RequireAuth>
        ),
    }
]);

export default router;