import { useState, useEffect } from "react";

const useEditData = (url, uid, makeRequest, token) => {
    const [person, setePrson] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!uid) return; // Do nothing if UID is not provided

        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await makeRequest(
                    `http://localhost:5000/api/${url}/${uid}`,
                    'GET',
                    null,
                    token
                );
                setePrson(response); // Set the person data from response
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData(); // Fetch person details on hook mount
    }, [url, uid, makeRequest, token]);

    const editData = async (updatedData, url) => {
        setLoading(true);

        // Create a payload with only the modified fields
        const changes = {};

        // Compare the updated data with the current person data
        Object.keys(updatedData).forEach((key) => {
            if (updatedData[key] !== person[key]) {
                changes[key] = updatedData[key];
            }
        });

        if (Object.keys(changes).length === 0) {
            // No changes, return early
            setError("No changes made.");
            setLoading(false);
            return;
        }

        try {
            const response = await makeRequest(
                `http://localhost:5000/api/${url}/${uid}`,
                'PUT',
                changes, // Only send the modified fields
                token
            );
            setePrson(response); // Update local state with the new person data
            return response; // Optionally return response
        } catch (err) {
            setError(err.message);
            throw err; // Throw error to be handled by component if needed
        } finally {
            setLoading(false);
        }
    };

    return {
        person,
        loading,
        error,
        editData,
    };
};

export default useEditData;
