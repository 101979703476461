import React, { useState } from "react";

function CreateTour() {

    const [name, setName] = useState('');
    const [details, setDetails] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');
    const [img, setImg] = useState('');
    const [imgPreview, setImgPreview] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImg(file);
            setImgPreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!img) {
            alert("Please upload an image.");
            return;
        }
        setIsUploading(false);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>
                        Tour Name:
                        <input
                        className="auth-input"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Price:
                        <input
                        className="auth-input"
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Category:
                        <input
                        className="auth-input"
                            type="text"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Upload Image:
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </label>
                </div>
                <div className="form-group">
                    {imgPreview && (
                        <div>
                            <h3>Image Preview:</h3>
                            <img
                                src={imgPreview}
                                alt="Preview"
                                style={{ width: '200px', height: 'auto' }}
                            />
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label>
                        Details:
                        </label>
                        <textarea
                        className="auth-input"
                            value={details}
                            onChange={(e) => setDetails(e.target.value)}
                            required
                        />

                </div>
                <button className="login-button" type="submit" disabled={isUploading}>
                    {isUploading ? 'Uploading...' : 'Create Tour'}
                </button>
            </form>
        </div>
    )
}

export default CreateTour;