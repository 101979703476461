import { useState } from 'react';

export const useDeleteData = (token, makeRequest) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteData = async (url, uid) => {
        setLoading(true);
        setError(null);

        const storedToken = token || localStorage.getItem('token');
        if (!storedToken) {
            console.log("Token is null:", token);
            setError("Token is missing.");
            setLoading(false);
            return;
        }

        try {
            // Call your backend API to delete the agent from Realtime Database and Firebase Authentication
            await makeRequest(
                `http://localhost:5000/api/${url}/${uid}`,
                'DELETE',
                null,
                storedToken
            );

            console.log("Person deleted successfully from Authentication and Realtime Database");

        } catch (error) {
            console.error("Error deleting agent:", error);
            setError("Failed to delete agent");
        } finally {
            setLoading(false);
        }
    };

    return { deleteData, loading, error };
};
